.content {
  background-color: #131216;
  box-sizing: border-box;
  height: 100%;
  overflow-y: auto;
  padding: 15px;
  position: relative;
  width: 100%;
}

#content-title {
  align-self: flex-start;
  color: #d2d2d2;
  flex-shrink: 0;
  /* position: absolute; */
  /* width: 320px; */
  /* height: 51px; */
  left: 0px;
  top: 0px;

  /* Heading/H4 */

  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 160%;
  text-transform: capitalize;
  color: #efefef;
}
.content-subtitle {
  width: 100%;
  color: rgb(239, 239, 239);
  font-family: Inter;
  font-size: 25px;
  font-weight: 700;
}
.margin-top-30 {
  margin-top: 30px !important;
}
#content-header {
  flex-shrink: 0;
  justify-content: space-between;
  margin-bottom: 4px;
  width: 100%;
}
.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-row {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.basic-light {
  color: #3a3a3a !important;
}
.basic-dark {
  color: #d0d0d0 !important;
}
#pdf-product-title {
  display: none;
}
#yearValue {
  display: none;
}
.selectedDate {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: auto;
}
